<template>
<div class="common">
    <div class="commonTop flex flex-ai-c flex-jc-sb" :style="{'left':isCollapse?'64px':'150px'}">
        <div class="name flex flex-ai-c">天缘汽配数字化经营管理系统 <span style="font-size:16px;margin-left:10px">{{bureau_name}}</span> </div>
        <div class="userbox flex flex-ai-c flex-jc-sb">
            <div class="msg flex flex-ai-c" @click="goMsg()" v-if="bureau_name!=''">
                <el-link type="primary" :underline="false" @click="goMsg()" v-if="bureau_name!=''">返回总部</el-link>
            </div>
            <div class="user">
                <el-dropdown>
                    <span class="el-dropdown-link flex flex-ai-c">
                        {{$root.userInfo.name}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item icon="el-icon-monitor" @click.native="goover()">进入后台</el-dropdown-item> -->
                        <!-- <el-dropdown-item icon="el-icon-unlock" @click.native="repwd()">修改密码</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>

    <div class="commonLeft" :style="{'width':isCollapse?'64px':'150px'}">
        <div class="nav">
            <el-aside>
                <div class="headImg">
                    <img :style="{'width':isCollapse?'42px':'60px'}" src="../../assets/avatars.jpg" alt="">
                </div>
                <el-menu background-color="#212123" :default-openeds="['/reportQuery']" text-color="#CDCDD6" :default-active="$route.path" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 145px )">
                    <template v-for="nav in navList">
                        <el-menu-item :index="nav.path" :key="nav.name" v-if="!nav.sub">
                            <i :class="nav.icon"></i>
                            <span slot="title">
                                {{nav.name}}
                            </span>
                        </el-menu-item>
                        <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                            <template slot="title">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span> {{nav.name}} </span>
                            </template>
                            <template v-for="item in nav.sub">
                                <el-menu-item :index="item.path" :key="item.name">
                                    <i :class="item.icon" v-if="item.icon"></i>
                                    <span style="font-size:13px;">{{item.name}}</span>
                                </el-menu-item>
                            </template>
                        </el-submenu>
                    </template>
                    <p style="height:50px;background:#212123"></p>
                </el-menu>

                <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'150px'}">
                    <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                </div>
            </el-aside>
        </div>
    </div>
    <div class="commonRight" :style="{'left':isCollapse?'64px':'150px'}">
        <router-view :isCollapse="isCollapse" />
    </div>

    <el-dialog title="修改密码" :visible.sync="repwdVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="380px" center>
        <el-form ref="formAuth" :model="pwdForm" :rules="formRules" :label-width="formLabelWidth">
            <el-form-item label="原密码" prop="old_password">
                <el-input size="medium" v-model="pwdForm.old_password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-input size="medium" show-password v-model="pwdForm.new_password"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="repwdEnter" style="width:100%">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 图片放大查看 -->
    <el-dialog title="图片查看" :visible.sync="dialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="350px">
        <img height="100%" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
export default {
    data: function() {
        var oldPassword = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入原密码'));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入新密码'));
            } else {
                if (this.pwdForm.newPassword !== '') {
                    this.$refs.formAuth.validateField('verifyPassword');
                }
                callback();
            }
        };
        var verifyPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.pwdForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            pwdForm: {
                id: ''
            },
            count: '',
            bureau_name: '',
            formRules: {
                old_password: [{
                    validator: oldPassword,
                    trigger: 'blur'
                }],
                new_password: [{
                    validator: newPassword,
                    trigger: 'blur'
                }],
                verifyPassword: [{
                    validator: verifyPassword,
                    trigger: 'blur'
                }],
            },
            formLabelWidth: '80px', //弹出form label 宽
            repwdVisible: false,
            defaultActive: this.$route.path,
            isCollapse: false,
            userInfo: {},
            dialogImageUrl: require('../../assets/er.png'), //照片
            dialogVisible: false, //照片
            navList: [{
                name: '统计概况',
                path: '/subOverview',
                icon: 'el-icon-s-finance',
            }, {
                name: '商品管理',
                path: '/subGoods',
                icon: 'el-icon-s-goods',
            }, {
                name: '订单管理',
                path: '/subOrder',
                icon: 'el-icon-s-order',
            }, {
                name: '退单管理',
                path: '/subChargeback',
                icon: 'el-icon-s-release',
            }, {
                name: '客户管理',
                path: '/subCustomer',
                icon: 'el-icon-user-solid',
            }, {
                name: '账号管理',
                path: '/subAdmin',
                icon: 'el-icon-user',
            }]
        }
    },
    mounted: function() {
        if (this.$route.query.name) sessionStorage.setItem('bureau_name', this.$route.query.name);
        this.bureau_name = sessionStorage.getItem('bureau_name')?sessionStorage.getItem('bureau_name'):'';
    },
    watch: {
        $route() {
            this.http.post('/login/state').then(re => {
                this.$root.userInfo = re.data
            })
        }
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
        // Promise.all 會等到數組內的 Promise 都 resolve 後才會繼續跑(then)
        let _token = sessionStorage.getItem('_token');
        Promise.all([
            axios.post(window.domain + '/login/state', {
                id: sessionStorage.getItem('userId')
            }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': _token
                }
            })
        ]).then(result => next(vm => {
            // 執行結果會按照上面順序放在 result 數組內，所以 result[0]，代表第一個函數的 resolve 結果
            if (result[0].data.code == 200) vm.$root.userInfo = result[0].data.data;
        }))
    },
    methods: {
        handleShowEr() {
            this.dialogVisible = true;
        },
        goMsg() {
            this.http.post('/sales.site/getBack', {
                id: 0
            }).then(() => {
                this.$router.push('/overview');
                sessionStorage.removeItem('bureau_name');
                sessionStorage.removeItem('bureau_id');
            })

        },
        goindex: function() {
            this.$router.push('/overview');
        },
        //nav跳转
        navTo: function(path) {
            this.$router.push(path);
        },
        //
        handleCollapse: function() {
            this.isCollapse = !this.isCollapse;
        },
        repwd: function() {
            this.repwdVisible = true
        },
        logOut: function() {
            this.http.post('/login/logout').then(() => {
                this.$router.push('/');
                sessionStorage.clear();
            })
        },
        goover() {
            this.$router.push('/setPersonnel')
        },
        repwdEnter: function() {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.http.post('/user/changePassword', {
                        old_password: this.$md5(this.pwdForm.old_password),
                        new_password: this.$md5(this.pwdForm.new_password),
                    }).then(() => {
                        this.repwdVisible = false;
                        this.$message({
                            message: '修改成功!',
                            type: 'success'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
    },
}
</script>
